import CONSTANTS from "common/constants";
import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { deactivateHospital } from "api/adminDashboardAPI";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import {
    getCurrentQuarter,
    hospitalApprovalStatus,
    wordSplit,
} from "common/utils";
import SortTableColumnButton from "components/SortTableColumnButton";
import { HospitalTableWrapper } from "./styled";
import {
    setQuarter,
    setYear,
} from "../MeasuresDateFilter/measuresDateFilter.slice";

interface Props {
    tab: string;
    pageNumber: number;
    hospitals: any;
    actionURL: string;
    isTableLoading: boolean;
    isActive: boolean;
    isDeactivated: boolean;
    close: any;
    setSortInd: any;
    sortInd: any;
}

export const HospitalTable = ({
    tab,
    pageNumber,
    hospitals,
    actionURL,
    isTableLoading,
    isActive,
    isDeactivated,
    close,
    setSortInd,
    sortInd,
}: Props) => {
    const dispatch = useDispatch();
    const facilityCode = "";
    const facilityName = "";

    const activateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const activateResult = await deactivateHospital(facilityCode, true);
        if (activateResult.success) {
            const index = hospitals.findIndex(
                (arr: any) => arr.facilityCode == facilityCode
            );
            if (index > -1) {
                hospitals.splice(index, 1);
            }
            const toast = {
                message: `${facilityName} activated successfully`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };
    const location = useLocation();

    return (
        <HospitalTableWrapper className="hospital-status-table w-100">
            <table
                className={`aui-responsive-status-table ${
                    isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                }`}
            >
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Organization Details
                                <div className="ml-2" />
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">Program Type</div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">Location</div>
                        </th>
                        <th scope="col">
                            <div className="aui-th">State</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hospitals.length > 0 &&
                        hospitals.map((hospital: any) => {
                            return (
                                <tr
                                    key={`${hospital.hospitalId}-${hospital.programsId}-${hospital.categoryId}`}
                                    className={hospitalApprovalStatus(tab)}
                                >
                                    <td data-title="Organization Details">
                                        <div className="aui-td">
                                            <div>
                                                {isDeactivated === false &&
                                                    (location?.state
                                                        ?.deactivateInd ===
                                                        true &&
                                                    location?.state
                                                        ?.hospitalInd ===
                                                        hospital.hospitalId ? (
                                                        hospital.hospitalName
                                                    ) : (
                                                        <Link
                                                            to={{
                                                                pathname: `${actionURL}${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                                state: {
                                                                    backToFaciclityManagement:
                                                                        "/admin/organization-management",
                                                                    adminfacilityMangemnetTab:
                                                                        tab,
                                                                    adminfacilityMangemnetPage:
                                                                        pageNumber,
                                                                    pageSort:
                                                                        sortInd,
                                                                    facilityName:
                                                                        hospital.hospitalName,
                                                                    deactivateInd:
                                                                        false,
                                                                    hospitalInd:
                                                                        hospital.hospitalId,
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                dispatch(
                                                                    setYear(
                                                                        new Date().getFullYear()
                                                                    )
                                                                );
                                                                dispatch(
                                                                    setQuarter(
                                                                        getCurrentQuarter()
                                                                    )
                                                                );
                                                                localStorage.setItem(
                                                                    "resetFilter",
                                                                    "false"
                                                                );
                                                            }}
                                                            className="btn-text-link-uline"
                                                        >
                                                            {
                                                                hospital.hospitalName
                                                            }
                                                        </Link>
                                                    ))}
                                                {isDeactivated === true && (
                                                    <Link
                                                        to={{
                                                            pathname: `${actionURL}${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                            state: {
                                                                backToFaciclityManagement:
                                                                    "/admin/organization-management",
                                                                adminfacilityMangemnetTab:
                                                                    tab,
                                                                adminfacilityMangemnetPage:
                                                                    pageNumber,
                                                                pageSort:
                                                                    sortInd,
                                                                facilityName:
                                                                    hospital.hospitalName,
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            localStorage.setItem(
                                                                "resetFilter",
                                                                "false"
                                                            );
                                                        }}
                                                        className="btn-text-link-uline"
                                                        aria-label={`View ${hospital.hospitalName} details`}
                                                    >
                                                        {hospital.hospitalName}
                                                    </Link>
                                                )}
                                                <div className="mt-1">
                                                    Organization Code:{" "}
                                                    <span className="abbr-code">
                                                        {wordSplit(
                                                            hospital.facilityCode
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Program Type">
                                        <div className="aui-td">
                                            {hospital.categoryName}
                                        </div>
                                    </td>
                                    <td data-title="Country">
                                        <div className="aui-td">
                                            {hospital.countryName}
                                        </div>
                                    </td>
                                    <td data-title="State">
                                        <div className="aui-td">
                                            {hospital.stateName}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <div
                role="alert"
                aria-atomic="true"
                className="table-emptystate-outer"
            >
                {hospitals.length < 1 && (
                    <div>
                        No organizations match your search.
                        <br />
                        Please try modifying your search criteria
                    </div>
                )}
            </div>
            {isDeactivated === true && (
                <div
                    className="modal fade show aui-modal"
                    id="activateModal"
                    tabIndex={-1}
                    aria-labelledby="activateModalLabel"
                    aria-modal="true"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className="text-center">
                                    <span
                                        id="activateModalLabel"
                                        className="sr-only"
                                    >
                                        Confirm Activate Organization
                                    </span>
                                    <p className="pb-2">
                                        Are you sure you want to activate this
                                        organization?
                                    </p>
                                    <div className="mt-4 d-flex justify-content-center">
                                        <button
                                            className="btn btn-secondary btn-round btn-sm mx-2"
                                            data-dismiss="modal"
                                            aria-label="Cancel Activation and close the modal"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-primary btn-round btn-sm mx-2"
                                            onClick={activateFacility}
                                            data-dismiss="modal"
                                            aria-label="Confirm Activate"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </HospitalTableWrapper>
    );
};

export default HospitalTable;
