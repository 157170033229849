import { useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    saveAgreementDetails,
    getAgreementDetails,
    getCountryProgramAgreement,
    getAgreementDownloadUrl,
} from "api/programAgreementAPI";
import { getHospitalById } from "api/hospitalApi";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import AgreementFileUpload from "components/AgreementFileUpload";
import { useDispatch } from "react-redux";
import CONSTANTS from "common/constants";
import { getAssetDetails } from "api/certificateAPI";
import { showToast } from "components/Toast/toast.slice";
import store from "app/store";
import { skipPayment, programSubscription } from "api/paymentAPI";
import {
    localDateToYYYYMMDDFormatString,
    parseJwt,
    sleep,
    wordSplit,
} from "common/utils";
import { HospitalDetail } from "../../styled";
import { getNavigationByHospitalId } from "../../api/usersApi";
import { BreadCrumb } from "../../components/BreadCrumb";

interface Props {
    history: any;
    isCorporation?: any;
    close?: any;
    setActiveTab?: any;
    activeTab?: any;
    setAgreementUploaded?: any;
    agreementUploaded?: boolean;
    handleAgreementDataChange?: any;
}

const HospitalProgramAgreementPage = ({
    history,
    isCorporation,
    close,
    setActiveTab,
    activeTab,
    setAgreementUploaded,
    agreementUploaded,
    handleAgreementDataChange,
}: Props) => {
    const params: any = useParams();
    const location = useLocation();
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [signedDate, setSignedDate] = useState<any>("");
    const [programName, setProgramName] = useState<any>("");
    const [agreementPdfPath, setAgreementPdfPath] = useState<any>("");
    const [agreementFileName, setAgreementFileName] = useState<any>("");
    const [disabled, setDisabled] = useState<any>(false);
    const [enableUpload, setEnableUpload] = useState<any>(true);
    const [recallInd, setRecallInd] = useState<boolean>(false);
    const [referenceId, setReferenceId] = useState<string>("");
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [prevState, setPrevState] = useState<any>();
    const dispatch = useDispatch();
    const hospitalId: any = isCorporation
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        isCorporation
            ? getAgreementDetailsToDisplay()
            : getNavigationByHospitalId(hospitalId)
                  .then((navigationResponse: any) => {
                      const { entity, path } = navigationResponse.data.url;
                      if (
                          entity === CONSTANTS.HOSPITAL &&
                          path === CONSTANTS.THANKYOU_PAGE
                      ) {
                          dispatch(
                              setPageLoadingStatus({ isPageLoading: false })
                          );
                          history.push("/successpayment");
                      } else if (
                          entity === CONSTANTS.HOSPITAL &&
                          path === CONSTANTS.PATHS.DASHBOARD
                      ) {
                          dispatch(
                              setPageLoadingStatus({ isPageLoading: false })
                          );
                          history.push("/userDashboard");
                      } else {
                          const elementsArray = [];
                          elementsArray.push(
                              {
                                  label: "Home",
                                  returnPath: {
                                      pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                      state: {
                                          pageNum: location?.state?.pageNum,
                                          search: location?.state?.search,
                                      },
                                  },
                              },
                              {
                                  label: "Organization Details",
                                  returnPath: {
                                      pathname: `${CONSTANTS.ROUTES.HOSPITAL_REGISTRATION}/${hospitalId}`,
                                      state: {
                                          pageNum: location?.state?.pageNum,
                                          search: location?.state?.search,
                                      },
                                  },
                              },
                              {
                                  label: "Programs",
                                  returnPath: {
                                      pathname: CONSTANTS.ROUTES.PROGRAMS,
                                      state: {
                                          pageNum: location?.state?.pageNum,
                                          search: location?.state?.search,
                                          emsInd: true,
                                      },
                                  },
                              },
                              {
                                  label: "Program Details",
                                  returnPath: {
                                      pathname: `${CONSTANTS.ROUTES.PROGRAM_REGISTRATION}/${params.programId}`,
                                      state: {
                                          pageNum: location?.state?.pageNum,
                                          search: location?.state?.search,
                                      },
                                  },
                              },
                              {
                                  label: "Agreement",
                                  returnPath: "",
                              }
                          );
                          setBreadcrumbItems(elementsArray);
                          getAgreementDetailsToDisplay();
                      }
                  })
                  .catch(() => {
                      dispatch(setPageLoadingStatus({ isPageLoading: false }));
                  });
    }, [agreementUploaded]);

    useEffect(() => {
        agreementPdfPath &&
            getAssetDetails(referenceId, true).then((presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    setAgreementPdfPath(
                        presignedURL.data?.assets?.[0].presignedUrl
                    );
                    sleep(270000).then(() => {
                        setRecallInd(!recallInd);
                    });
                }
            });
    }, [recallInd]);

    const getAgreementDetailsToDisplay = () => {
        Promise.all([
            getHospitalById(Number(hospitalId)),
            getAgreementDetails(params.programId, hospitalId),
        ])
            .then((response: any) => {
                const hospitalInfoResponse = response[0];
                const agreementData = response[1];
                if (hospitalInfoResponse.data) {
                    // Pre-populate 1) Facility name,2)First and Last Name of Signer 3)Email Of Signer from Hospital Info
                    agreementData.data.facilityName = agreementData.data
                        ?.facilityName
                        ? agreementData.data.facilityName
                        : hospitalInfoResponse.data.hospital.facilityName;
                    agreementData.data.signerName = agreementData.data
                        ?.signerName
                        ? agreementData.data.signerName
                        : hospitalInfoResponse.data.hospital.signatoryName;
                    agreementData.data.signerEmail = agreementData.data
                        ?.signerEmail
                        ? agreementData.data.signerEmail
                        : hospitalInfoResponse.data.hospital.signatoryEmail;
                }
                if (agreementData.data) {
                    setProgramName(agreementData.data.programName);
                    if (agreementData.data.signedDate) {
                        setAgreementInfo(agreementData.data);
                        if (handleAgreementDataChange) {
                            handleAgreementDataChange(agreementData.data);
                        }
                        setSignedDate(
                            localDateToYYYYMMDDFormatString(
                                agreementData.data.signedDate
                            )
                        );
                    } else {
                        const todayDate = localDateToYYYYMMDDFormatString(
                            new Date()
                        );
                        agreementData.data.signedDate = todayDate;
                        setSignedDate(todayDate);
                        setAgreementInfo(agreementData.data);
                    }
                    if (agreementData.data.agreementReferenceId) {
                        getUploadedAgreementInfo(
                            agreementData.data.agreementReferenceId,
                            agreementData
                        );
                        setEnableUpload(false);
                    } else {
                        setPrevState({
                            agreementInfo: {
                                ...agreementData.data,
                                signerTitle: agreementData.data.signerTitle
                                    ? agreementData.data.signerTitle
                                    : "",
                                participantTaxId: agreementData.data
                                    .participantTaxId
                                    ? agreementData.data.participantTaxId
                                    : "",
                            },
                            signedDate: localDateToYYYYMMDDFormatString(
                                agreementData.data.signedDate
                            ),
                        });
                    }
                }
                if (hospitalInfoResponse.data && agreementData.data) {
                    getCountryProgramAgreement(
                        params.programId,
                        params.categoryId,
                        hospitalInfoResponse.data.hospital?.countryId
                    )
                        .then((result) => {
                            if (result?.data?.agreements?.referenceId) {
                                setReferenceId(
                                    result.data.agreements.referenceId
                                );
                                getAssetDetails(
                                    result.data.agreements.referenceId,
                                    true
                                )
                                    .then((presignedURL: any) => {
                                        if (
                                            presignedURL.data?.assets?.[0]
                                                .isDownloadable &&
                                            presignedURL.data?.assets?.[0]
                                                .presignedUrl
                                        ) {
                                            setAgreementPdfPath(
                                                presignedURL.data?.assets?.[0]
                                                    .presignedUrl
                                            );
                                            dispatch(
                                                setPageLoadingStatus({
                                                    isPageLoading: false,
                                                })
                                            );
                                            sleep(270000).then(() => {
                                                setRecallInd(!recallInd);
                                            });
                                        } else {
                                            dispatch(
                                                setPageLoadingStatus({
                                                    isPageLoading: false,
                                                })
                                            );
                                        }
                                    })
                                    .catch(() => {
                                        setPageLoading(false);
                                    });
                            } else {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            }
                        })
                        .catch(() => {
                            setPageLoading(false);
                        });
                }
            })
            .catch(() => {
                setPageLoading(false);
            });
    };
    const setPageLoading = (isPageLoading: boolean) => {
        dispatch(
            setPageLoadingStatus({
                isPageLoading,
            })
        );
    };

    const jumpToPrevTab = () => {
        cancelEdit();
        setActiveTab(activeTab - 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const cancelEdit = () => {
        setAgreementInfo(prevState.agreementInfo);
        setSignedDate(prevState.signedDate);
        setAgreementFileName(prevState.agreementFileName);
    };

    const jumpToNextTab = (increament: number) => {
        setAgreementUploaded(true);
        setDisabled(false);
        setActiveTab(activeTab + increament);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const submitAgreement = (event: any) => {
        event.preventDefault();
        if (referenceId && !agreementInfo.agreementReferenceId) {
            const toast = {
                message: "Please Upload Signed Agreement.",
                code: "Error:",
            };
            store.dispatch(showToast(toast));
        } else {
            setDisabled(true);
            if (params.programId === CONSTANTS.EMS_PROGRAM_ID) {
                agreementInfo.programAgreement = true;
            }
            const { categoryId } = params;
            delete agreementInfo.programCategoryId; // no need to update category id from agreement page
            delete agreementInfo.programName; // no need to update program Name from agreement page
            delete agreementInfo.updatedAt; // no need to update updatedAt from agreement page
            delete agreementInfo.updatedBy; // no need to update updatedBy from agreement page
            delete agreementInfo.signedDate; // no need to update signedDate from agreement page
            delete agreementInfo.agreementUploadedBy;
            saveAgreementDetails(
                agreementInfo,
                params.programId,
                hospitalId,
                isEUP
            )
                .then((data) => {
                    if (data.success == true) {
                        if (params.programId !== "22") {
                            getHospitalById(hospitalId)
                                .then((response: any) => {
                                    if (
                                        response?.data?.hospital?.countryCode ==
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE
                                    ) {
                                        isCorporation
                                            ? jumpToNextTab(1)
                                            : history.push(
                                                  `/paymentSummary/program/${params.programId}/category/${categoryId}`
                                              );
                                    } else {
                                        // make lastModeOfPayment as 'Offline', so that in admin approval page, it will be appropriate
                                        isCorporation
                                            ? jumpToNextTab(2)
                                            : handleSkipPaymentSubmit(
                                                  params.programId,
                                                  categoryId
                                              );
                                    }
                                })
                                .catch((error) => {
                                    setDisabled(false);
                                });
                        } else {
                            handleSkipPaymentSubmit(
                                params.programId,
                                categoryId
                            );
                        }
                    } else {
                        setDisabled(false);
                    }
                })
                .catch((error) => {
                    if (
                        !isCorporation &&
                        error.details &&
                        error.details[0]?.message ===
                            CONSTANTS.REGISTRATION_COMPLETED_BY_CORPORATION
                    ) {
                        history.push("/successpayment");
                    } else if (
                        isCorporation &&
                        error.message &&
                        error.message ==
                            CONSTANTS.AGREEMENT_UPLOADED_BY_FACILITY
                    ) {
                        sleep(1000).then(() => {
                            window.location.reload();
                        });
                    } else if (
                        isCorporation &&
                        error.message &&
                        error.code == CONSTANTS.FORBIDDEN
                    ) {
                        history.push("/corporation/pending-approval");
                    } else if (
                        !isCorporation &&
                        error.message &&
                        error.message ==
                            CONSTANTS.AGREEMENT_UPLOADED_BY_CORPORATION
                    ) {
                        sleep(1000).then(() => {
                            window.location.reload();
                        });
                    }
                    setDisabled(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    };

    const handleChange = (event: any) => {
        const permissions = [
            "permissionEvents",
            "permissionMedia",
            "permissionBanners",
            "permissionAds",
        ];
        if (
            permissions.indexOf(event.target.name) > -1 &&
            !event.target.checked
        ) {
            let hidePublication = true;
            for (
                let agreementIndex = 0;
                agreementIndex < permissions.length;
                agreementIndex++
            ) {
                if (
                    permissions[agreementIndex] !== event.target.name &&
                    agreementInfo[permissions[agreementIndex]]
                ) {
                    hidePublication = false;
                    break;
                }
            }
            if (hidePublication) {
                setAgreementInfo({
                    ...agreementInfo,
                    publicationName: "",
                    [event.target.name]:
                        event.target.type == "checkbox"
                            ? event.target.checked
                            : event.target.name == "publicationName" ||
                              event.target.name == "facilityName"
                            ? event.target.value.replace(/[><]/g, "")
                            : event.target.value,
                });
            } else {
                setAgreementInfo({
                    ...agreementInfo,
                    [event.target.name]:
                        event.target.type == "checkbox"
                            ? event.target.checked
                            : event.target.value,
                });
            }
        } else {
            setAgreementInfo({
                ...agreementInfo,
                [event.target.name]:
                    event.target.type == "checkbox"
                        ? event.target.checked
                        : event.target.name == "publicationName" ||
                          event.target.name == "facilityName"
                        ? event.target.value.replace(/[><]/g, "")
                        : event.target.value,
            });
        }
    };

    /**
     * Method to update skip payment info and redirect to 'thank you' page.
     *
     * @param programId
     * @param categoryId
     */
    const handleSkipPaymentSubmit = async (programId: any, categoryId: any) => {
        Promise.all([
            skipPayment(hospitalId, programId, categoryId),
            programSubscription(
                hospitalId,
                programId,
                categoryId,
                CONSTANTS.PAYMENT_OFFLINE
            ),
        ])
            .then((responses: any) => {
                if (responses[0].success && responses[1].success) {
                    history.push("/successpayment");
                } else {
                    throw responses.error;
                }
            })
            .catch((error: any) => {
                if (
                    error.details &&
                    error.details[0]?.message === CONSTANTS.PAYMENT_COMPLETE_MSG
                ) {
                    history.push("/successpayment");
                }
                setDisabled(false);
            });
    };

    const redirectToProgramPage = () => {
        history.push(`/program/register/${params.programId}`);
    };

    const setFileData = (data: any) => {
        setAgreementInfo({
            ...agreementInfo,
            agreementReferenceId: data.uploadId,
            agreementUploadedBy: isCorporation
                ? CONSTANTS.USER_ROLES.CORPORATION_ADMIN
                : agreementInfo.agreementUploadedBy,
        });
        setAgreementFileName(data.fileName);
    };

    const getUploadedAgreementInfo = async (
        uploadId: any,
        agreementData: any
    ) => {
        const uploadedAgreementInfo: any = await getAgreementDownloadUrl(
            uploadId,
            hospitalId
        );
        setAgreementFileName(uploadedAgreementInfo.data.uploads.fileName);
        setPrevState({
            agreementInfo: {
                ...agreementData.data,
                signerTitle: agreementData.data.signerTitle
                    ? agreementData.data.signerTitle
                    : "",
                participantTaxId: agreementData.data.participantTaxId
                    ? agreementData.data.participantTaxId
                    : "",
            },
            signedDate: localDateToYYYYMMDDFormatString(
                agreementData.data.signedDate
            ),
            agreementFileName: uploadedAgreementInfo.data.uploads.fileName,
        });
    };

    const downloadUploadedAgreementInfo = async () => {
        const uploadedAgreementInfo: any = await getAgreementDownloadUrl(
            agreementInfo.agreementReferenceId,
            hospitalId
        );
        window.location.assign(uploadedAgreementInfo.data.uploads.fileUrl);
    };

    const getDisabledInd = () => {
        if (
            (!isCorporation &&
                agreementInfo.agreementUploadedBy ===
                    CONSTANTS.USER_ROLES.CORPORATION_ADMIN) ||
            (isCorporation &&
                agreementInfo.agreementUploadedBy ===
                    CONSTANTS.USER_ROLES.HOSPITAL_ADMIN &&
                agreementInfo.agreementReferenceId)
        ) {
            return true;
        }
        return false;
    };

    return (
        <HospitalDetail>
            <div className="container reg-container">
                <div className="pg-header mb-4">
                    {parseJwt(window.localStorage.getItem("userAccessToken"))
                        .role_code == "HOSPITAL_ADMIN" ? (
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    ) : (
                        " "
                    )}
                    {isCorporation ? (
                        <h1 className="h4 font-500">Agreement Details</h1>
                    ) : (
                        <h1 className="h2 mb-2">{programName}</h1>
                    )}
                    <p>Please complete the below agreement process</p>
                </div>
                <div className="row">
                    <div className="pb-3 font-14 d-flex flex-row-reverse col-lg-12">
                        <div className="mand-field">
                            <sup>*</sup>
                            mandatory fields
                        </div>
                    </div>
                </div>
                <form className="reg-form" onSubmit={submitAgreement}>
                    <fieldset disabled={disabled}>
                        {referenceId && (
                            <>
                                <p>
                                    Click here to view the certification
                                    agreement. An authorized signature is
                                    required to complete the agreement process.
                                </p>
                                <div className="agreement-process">
                                    <div className="agreement-steps-header mb-4 pb-3 ">
                                        <h2 className="h6 mb-0 d-inline">
                                            Steps for completing and signing the
                                            Agreement
                                        </h2>
                                        <sup className="agreement-mand">*</sup>
                                    </div>
                                    <ol className="agreement-steps mb-4">
                                        <li>
                                            <span>
                                                Open the PDF of the Agreement
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Fill in the Required Fields
                                                (Yellow)
                                            </span>
                                        </li>
                                        <li>
                                            <span>Save to Your Desktop</span>
                                        </li>
                                        <li>
                                            <span>
                                                Print and take for Signature
                                                (Please see Quick Reference
                                                Guide for special exceptions)
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Upload Final Version of your
                                                signed Agreement using the
                                                Upload Agreement button
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Click Submit to advance to the
                                                next screen
                                            </span>
                                        </li>
                                    </ol>
                                    <p>
                                        In the event{" "}
                                        <span className="font-red">
                                            “Red Lines”
                                        </span>{" "}
                                        are required by your Legal team, do not
                                        upload Agreement until the final version
                                        has been approved and signed.
                                    </p>
                                    {programName ? (
                                        <div className="download-wrapper">
                                            <div className="contract-details d-flex d-md-block flex-column flex-grow-1">
                                                <div className="contract-details-item">
                                                    <span>
                                                        View / Download
                                                        Agreement:{" "}
                                                    </span>
                                                    <a
                                                        href={agreementPdfPath}
                                                        className="agmt-title p-1"
                                                        aria-label={`View / Download Agreement for ${programName}`}
                                                    >
                                                        {" "}
                                                        {programName}
                                                    </a>
                                                </div>
                                                <div className="d-sm-flex flex-nowrap justify-content-end upload-agreement-btn">
                                                    <button
                                                        disabled={
                                                            !(
                                                                enableUpload &&
                                                                enableUpload ==
                                                                    true
                                                            )
                                                        }
                                                        type="button"
                                                        className="btn btn-round btn-primary text-nowrap mt-4 mt-md-0 upload-agr-btn"
                                                        data-toggle="modal"
                                                        data-target={
                                                            enableUpload &&
                                                            enableUpload == true
                                                                ? "#uploadAgreementModal"
                                                                : ""
                                                        }
                                                    >
                                                        Upload Agreement
                                                    </button>
                                                    <AgreementFileUpload
                                                        onSave={setFileData}
                                                        uploadId={
                                                            agreementInfo.agreementReferenceId
                                                        }
                                                    />
                                                </div>
                                                {agreementFileName && (
                                                    <div className="contract-details-uploaded">
                                                        <span>
                                                            View Uploaded
                                                            Agreement:{" "}
                                                        </span>
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                downloadUploadedAgreementInfo
                                                            }
                                                            className="btn btn-text agmt-title p-1"
                                                            aria-label={`View Uploaded Agreement ${agreementFileName}`}
                                                        >
                                                            {" "}
                                                            {agreementFileName}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        )}
                        {params.programId !== CONSTANTS.EMS_PROGRAM_ID && (
                            <div
                                role="group"
                                aria-labelledby="required-checkbox"
                                className="form-group required"
                            >
                                <div className="mb-3">
                                    <h2
                                        className="font-600 mb-0 h8 d-inline label-form"
                                        id="required-checkbox"
                                    >
                                        Click the below statement to accept the
                                        Agreement
                                    </h2>
                                    <sup>*</sup>
                                </div>
                                <div className="form-group form-check agr-cond-check">
                                    <input
                                        required
                                        name="programAgreement"
                                        onChange={handleChange}
                                        checked={
                                            agreementInfo.programAgreement ==
                                            true
                                        }
                                        type="checkbox"
                                        value=""
                                        id="termsandconditions"
                                        aria-invalid="false"
                                    />
                                    <label htmlFor="termsandconditions">
                                        I agree that the Program Participant
                                        will be bound by the Terms and
                                        Conditions of the Agreement, and that I
                                        have full authority to bind the Program
                                        Participants to the terms and conditions
                                        of such Agreement. Signature of
                                        Authorized Program Participant
                                        Representative.
                                    </label>
                                </div>
                            </div>
                        )}
                        <div
                            role="group"
                            aria-labelledby="permission-agreement"
                        >
                            <h2
                                className="font-600 mb-3 h7"
                                id="permission-agreement"
                            >
                                Permissions Agreement:
                            </h2>
                            <div className="mb-4">
                                <p>
                                    I Agree to give the American Heart
                                    Association/American Stroke Association
                                    permission to use our name for the following
                                    recognition opportunities. Remove checks for
                                    any situations which your organization does
                                    not give permission.
                                </p>
                                <div className="form-check mb-2">
                                    <input
                                        name="permissionEvents"
                                        onChange={handleChange}
                                        checked={
                                            agreementInfo.permissionEvents ==
                                            true
                                        }
                                        type="checkbox"
                                        value=""
                                        disabled={getDisabledInd()}
                                        id="agreementRecognitionEvents"
                                    />
                                    <label htmlFor="agreementRecognitionEvents">
                                        Recognition Events
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input
                                        name="permissionMedia"
                                        onChange={handleChange}
                                        checked={
                                            agreementInfo.permissionMedia ==
                                            true
                                        }
                                        type="checkbox"
                                        value=""
                                        disabled={getDisabledInd()}
                                        id="agreementWEbsiteAndMedia"
                                    />
                                    <label
                                        htmlFor="agreementWEbsiteAndMedia"
                                        aria-label="American Heart Association website, digital media, mobile apps"
                                    >
                                        <span aria-hidden="true">AHA </span>
                                        <span className="sr-only">
                                            American heart Association
                                        </span>
                                        website, digital media, mobile apps
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input
                                        name="permissionBanners"
                                        onChange={handleChange}
                                        checked={
                                            agreementInfo.permissionBanners ==
                                            true
                                        }
                                        type="checkbox"
                                        value=""
                                        disabled={getDisabledInd()}
                                        id="agreementBanners"
                                    />
                                    <label htmlFor="agreementBanners">
                                        Conference banners and signage
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <input
                                        name="permissionAds"
                                        onChange={handleChange}
                                        checked={
                                            agreementInfo.permissionAds == true
                                        }
                                        type="checkbox"
                                        value=""
                                        disabled={getDisabledInd()}
                                        id="agreementAds"
                                    />
                                    <label htmlFor="agreementAds">
                                        Program promotion as permitted
                                    </label>
                                </div>
                                {!referenceId &&
                                    (agreementInfo.permissionEvents == true ||
                                        agreementInfo.permissionMedia == true ||
                                        agreementInfo.permissionBanners ==
                                            true ||
                                        agreementInfo.permissionAds ==
                                            true) && (
                                        <div className="form-group row required">
                                            <div className="col-md-5 form-label">
                                                <label htmlFor="agrpublicationname">
                                                    Enter Exact Organization
                                                    Name for Publications
                                                </label>
                                            </div>
                                            <div className="col-md-7 col-lg-6">
                                                <input
                                                    name="publicationName"
                                                    onChange={handleChange}
                                                    value={
                                                        agreementInfo.publicationName
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id="agrpublicationname"
                                                    required
                                                    minLength={3}
                                                    maxLength={200}
                                                    aria-describedby="pubName"
                                                />
                                                <div
                                                    className="form-help"
                                                    id="pubName"
                                                >
                                                    (Enter 3 to 200 characters)
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div role="group" aria-labelledby="sig-authorized">
                            <h2
                                className="h5 pt-2 font-400 agr-cond-form"
                                id="sig-authorized"
                            >
                                Signature of Authorized Program Participant
                                Representative
                            </h2>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="agrfacilityname"
                                        className="label-form"
                                    >
                                        Organization Name
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        name="facilityName"
                                        onChange={handleChange}
                                        value={agreementInfo.facilityName}
                                        type="text"
                                        className="form-control"
                                        id="agrfacilityname"
                                        required
                                        minLength={3}
                                        maxLength={200}
                                        aria-describedby="facName"
                                    />
                                    <div className="form-help" id="facName">
                                        (Enter 3 to 200 characters)
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="signername"
                                        className="label-form"
                                    >
                                        First and Last Name of Signer
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        pattern={
                                            CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                        }
                                        name="signerName"
                                        onChange={handleChange}
                                        value={agreementInfo.signerName}
                                        type="text"
                                        className="form-control"
                                        id="signername"
                                        readOnly={!isCorporation}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="signertitle"
                                        className="label-form"
                                    >
                                        Title of Signer
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        pattern={
                                            CONSTANTS.REGEX_ALLOW_ALPHABET_SPACE_DOT
                                        }
                                        name="signerTitle"
                                        onChange={handleChange}
                                        value={agreementInfo.signerTitle}
                                        type="text"
                                        className="form-control"
                                        id="signertitle"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="signeremail"
                                        className="label-form"
                                    >
                                        Email of Signer
                                        <sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                        name="signerEmail"
                                        onChange={handleChange}
                                        value={agreementInfo.signerEmail}
                                        type="email"
                                        className="form-control"
                                        id="signeremail"
                                        readOnly={!isCorporation}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row required">
                                <div className="col-md-5 form-label">
                                    <label
                                        htmlFor="date"
                                        className="label-form"
                                    >
                                        Date<sup>*</sup>
                                    </label>
                                </div>
                                <div className="col-md-7 col-lg-6">
                                    <input
                                        name="signedDate"
                                        value={signedDate}
                                        type="text"
                                        className="form-control"
                                        id="date"
                                        required
                                        readOnly
                                    />
                                </div>
                            </div>
                            {params.programId !== "22" && (
                                <div className="form-group row">
                                    <div className="col-md-5 form-label">
                                        <label htmlFor="participantid">
                                            Program Participant Tax ID (US site
                                            only)
                                        </label>
                                    </div>
                                    <div className="col-md-7 col-lg-6">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_PARTICIPANTID
                                            }
                                            name="participantTaxId"
                                            onChange={handleChange}
                                            value={
                                                agreementInfo.participantTaxId
                                            }
                                            type="text"
                                            className="form-control"
                                            id="participantid"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {params.programId !== "22" && (
                            <div className="pt-4">
                                <h2 className="h5 pt-2 mb-4 font-400">
                                    Signature of American Heart Association
                                    Authorized Representative
                                </h2>
                                {/* <div className="pb-4">Dyba Syed</div> */}
                                <div className="pb-4">
                                    Director Quality &amp; Healthcare
                                    Certifications
                                </div>
                                <div className="font-black font-500">
                                    <span>Date - </span>{" "}
                                    <span>{signedDate} (YYYY-MMM-DD)</span>
                                </div>
                            </div>
                        )}
                        <div className="reg-btn row">
                            {isCorporation ? (
                                <div className="col-md-6 offset-md-5 px-0 px-md-3 d-flex flex-column flex-md-row">
                                    <button
                                        onClick={close}
                                        type="button"
                                        className="btn btn-round btn-secondary mr-0 mr-md-3 mb-4 mb-md-0"
                                        aria-describedby="admin-agreementdetails"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={jumpToPrevTab}
                                        type="button"
                                        className="btn btn-round btn-secondary mr-0 mr-md-4 mb-4 mb-md-0"
                                        disabled={disabled}
                                        aria-describedby="admin-programdetails"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        disabled={disabled}
                                        type="submit"
                                        className={`btn btn-round btn-primary ${
                                            disabled
                                                ? CONSTANTS.BUTTON_SPINNER
                                                : ""
                                        }`}
                                        aria-describedby="admin-billingdetails"
                                        aria-label="Submit to complete the Agreement process"
                                    >
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <div className="col-md-6 offset-md-5 px-0 px-md-3 d-flex flex-column flex-md-row">
                                    <button
                                        type="button"
                                        onClick={redirectToProgramPage}
                                        className="btn btn-round btn-secondary mr-0 mr-md-4 mb-4 mb-md-0"
                                        aria-label="back to program detail page"
                                    >
                                        Back
                                    </button>
                                    <button
                                        disabled={disabled}
                                        type="submit"
                                        className={`btn btn-round btn-primary ${
                                            disabled
                                                ? CONSTANTS.BUTTON_SPINNER
                                                : ""
                                        }`}
                                        aria-label="Submit to complete the Agreement process"
                                    >
                                        Submit
                                    </button>
                                </div>
                            )}
                        </div>
                    </fieldset>
                </form>
            </div>
        </HospitalDetail>
    );
};

export default HospitalProgramAgreementPage;
