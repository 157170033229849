import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
    getArchivalAccordionDatesAPI,
    getArchivalCertificateAPI,
} from "api/archivalDocumentIntegrationAPI";
import { getAssetDetails } from "api/certificateAPI";
import { localDateToYYYYMMDDFormatString, sleep } from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import { getHospitalById } from "api/hospitalApi";
import { logError } from "utils/logger.utils";
import CONSTANTS from "common/constants";
import { useDispatch } from "react-redux";
import { ArchivedCertificateWrapper } from "./styled";
import { setPageLoadingStatus } from "../../components/Loader/loader.slice";

interface Props {
    docArcivedDate: string;
    docApprovedDate: string;
    firstRenewingDate: any;
    secondRenewingDate?: any;
}

export const ArchivedCertificate = ({
    docArcivedDate,
    docApprovedDate,
    firstRenewingDate,
    secondRenewingDate,
}: Props) => {
    interface certificateDetails {
        [x: string]: any;
        certificateState: any;
        certificateCity: any;
        expiredOn: string;
        grantedOn: string;
        programCertificateName: string;
        programCertificateFacilityName: string;
        programCertificateGrantedOn: string;
        programCertificateExpiryDate: string;
        programCertificateCity: string;
        programCertificateState: string;
        programCertificateCountry: string;
        programCertificatePath: string;
        brandingMaterials: Array<brandingMaterials>;
    }
    interface brandingMaterials {
        materialId: string;
        referenceId: string;
        isAllowed: boolean;
    }
    const dispatch = useDispatch();
    const params: any = useParams();
    const [archivedLatestDate, setArchivedLatestDate] = useState<any>(null);
    const [hospitalCertificateDetails, setHospitalCertificateDetails] =
        useState<certificateDetails>(Object);
    const [referenceIdList, setReferenceIdList] = useState<string>("");
    const [assets, setAssets] = useState<any>("");
    const [organizationName, setOrganizationName] = useState<string>("");
    const [signedUrl, setSignedUrl] = useState<any>(null);
    const [countryCode, setCountryCode] = useState<any>("");
    const [recallInd, setRecallInd] = useState<boolean>(false);
    const [archivedDate, setArchivedate] = useState<any>(params?.archivedDate);
    const programId = localStorage.getItem("selectedProgramId");
    const [isCertificateLoaded, setIsCertificateLoaded] =
        useState<boolean>(false);
    const [approvedDate, setApprovedDate] = useState<string>("");
    const [approvalStatus, setApprovalStatus] = useState<any>();

    const getAssets = (referenceIdLists: string) => {
        getAssetDetails(referenceIdLists, true).then((result: any) => {
            if (
                result.statusCode == 200 &&
                Object.keys(result.data).length > 0
            ) {
                setAssets(result.data.assets);
                sleep(270000).then(() => {
                    setRecallInd(!recallInd);
                });
            }
        });
    };

    useEffect(() => {
        const archDate: any = localStorage.getItem("selectedArchivedDate");
        setArchivedate(archDate);
    }, []);
    useEffect(() => {
        if (docArcivedDate && docArcivedDate !== "undefined") {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            getArchivalAccordionDatesAPI(params.hospitalId).then((res: any) => {
                if (res.statusCode == 200) {
                    setIsCertificateLoaded(true);
                    const latestArchiveDate =
                        res.data.archiveDates[res.data.archiveDates.length - 1];
                    setArchivedLatestDate(latestArchiveDate);
                    if (res.data.archiveDates.length > 1) {
                        setApprovedDate(
                            res.data.archiveDates[
                                res.data.archiveDates.length - 2
                            ]
                        );
                    } else {
                        setApprovedDate(res.data.approvedDate);
                    }
                }
            });
            getArchivalCertificateAPI(params?.hospitalId, docArcivedDate)
                .then((res: any) => {
                    if (res.statusCode === 200) {
                        setIsCertificateLoaded(true);
                        setSignedUrl(res.data.certficatePath);
                        const brandingMaterialArray: any = [];
                        res.data.certificates.forEach((data: any) => {
                            if (data.archiveType === "CERTIFICATE") {
                                setOrganizationName(data?.facilityName);
                                setHospitalCertificateDetails(data);
                                setApprovalStatus(data?.lastApprovalStatus);
                            } else if (
                                data.archiveType === "BRANDING_MATERIAL"
                            ) {
                                brandingMaterialArray.push(data.referenceId);
                            }
                        });

                        const referenceList = brandingMaterialArray.join(",");
                        setReferenceIdList(brandingMaterialArray.join(","));
                        if (brandingMaterialArray.length > 0) {
                            getAssets(referenceList);
                        }
                        getHospitalById(params.hospitalId)
                            .then((result: any) => {
                                if (result.statusCode == 200) {
                                    setOrganizationName(
                                        result.data.hospital.facilityName
                                    );
                                    setCountryCode(
                                        result.data.hospital.countryCode
                                    );
                                }
                            })
                            .catch((error: any) => {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                                logError(error);
                            });
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch((err) => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    dispatch(showToast(err));
                });
        }
    }, [docArcivedDate, firstRenewingDate, secondRenewingDate]);

    useEffect(() => {
        if (docArcivedDate && docArcivedDate !== "no-archived-date-found") {
            referenceIdList &&
                getArchivalCertificateAPI(
                    params.hospitalId,
                    params?.archivedDate
                )
                    .then((response: any) => {
                        if (response.statusCode == 200) {
                            const { certificates } = response.data;
                            setSignedUrl(response.data.certificatePath);
                            if (Object.keys(certificates).length > 0) {
                                getAssets(referenceIdList);
                            }
                        }
                    })
                    .catch((err) => {
                        dispatch(showToast(err));
                    });
        }
    }, [recallInd]);

    return (
        <>
            {isCertificateLoaded && (
                <ArchivedCertificateWrapper
                    className="archived-main-content"
                    id="archived-certificate"
                >
                    <div className="year-period" id="certificate-heading">
                        Archived Certificate :{" "}
                        {localDateToYYYYMMDDFormatString(firstRenewingDate)} to{" "}
                        {localDateToYYYYMMDDFormatString(secondRenewingDate)}
                    </div>
                    <div className="d-flex org-heading align-items-center">
                        <h1 className="h2 font-400 mb-0">{organizationName}</h1>
                        {approvalStatus && (
                            <div className="org-status ml-4">
                                {approvalStatus}
                            </div>
                        )}
                    </div>
                    <div className="mt-4">
                        {!signedUrl && (
                            <div className="text-center py-5 my-5">
                                <img
                                    src="/images/nocertificate.svg"
                                    alt="No Certificate"
                                />
                                <div className="my-5 h6 font-600">
                                    No Certificate Awarded
                                </div>
                            </div>
                        )}
                        {signedUrl && (
                            <div>
                                <h2 className="h4 font-600 cert-prm">
                                    {
                                        hospitalCertificateDetails?.programCertificateName
                                    }
                                </h2>
                                <p>
                                    On behalf of the American Heart Association
                                    please accept our congratulations for
                                    achieving this well-deserved recognition.
                                    This recognition is a testament to your hard
                                    work and dedication in serving your
                                    population and your community. Below, you
                                    will find a marketing toolkit for your
                                    program. These resources can be used to
                                    share your achievement publicly.
                                </p>

                                <img
                                    src={signedUrl}
                                    alt={`
                                    ${
                                        hospitalCertificateDetails?.programCertificateName
                                    }
                                    ${
                                        params.programId == 14 &&
                                        params.categoryId == 5
                                            ? "award,"
                                            : "certification,"
                                    }
                                    awarded to
                                    ${organizationName},
                                    city ${
                                        hospitalCertificateDetails?.certificateCity
                                    },
                                    ${
                                        countryCode ==
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE
                                            ? `state ${hospitalCertificateDetails?.certificateState},`
                                            : `country ${hospitalCertificateDetails?.certificateCountry},`
                                    }
                                    granted on: ${
                                        hospitalCertificateDetails?.grantedOn
                                    } , and expires on: ${
                                        hospitalCertificateDetails?.expiredOn
                                    }`}
                                    className="cert-img mt-3"
                                />
                            </div>
                        )}
                        {assets && assets.length > 0 && (
                            <div>
                                <h4 className="h5 font-600 mt-5 mb-4">
                                    Downloads
                                </h4>
                                <div className="cert-tags">
                                    {assets.length
                                        ? assets.map((assetDetail: any) => {
                                              return assetDetail.assetName ==
                                                  "Award Certificate" ? (
                                                  <a
                                                      key={assetDetail.assetId}
                                                      href={signedUrl}
                                                      className="cert-tag-btn"
                                                  >
                                                      Certificate
                                                  </a>
                                              ) : (
                                                  <a
                                                      key={assetDetail.assetId}
                                                      href={
                                                          assetDetail.presignedUrl
                                                      }
                                                      className="cert-tag-btn"
                                                  >
                                                      {assetDetail.assetName}
                                                  </a>
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                        )}
                    </div>
                </ArchivedCertificateWrapper>
            )}
        </>
    );
};
