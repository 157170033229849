import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import CONSTANTS from "common/constants";
import config from "../config";

const API = new APIUtils();
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const getHospitalProgramMeasures = (
    hospitalId: number,
    programId: number,
    categoryId: number,
    startDate: string | null,
    endDate: string | null
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let url = `${
                config[config.env].apiEndpoints.accounts
            }/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/measures?region=${encodeURIComponent(
                timeZone
            )}`;

            if (startDate && endDate) {
                url += `&startDate=${startDate}&endDate=${endDate}`;
            }

            const APIRes = await API.GET(url);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const createHospitalProgramMeasures = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    hospitalMeasuresObj: any,
    startDate: string | null,
    endDate: string | null
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                measuresData: hospitalMeasuresObj,
                startDate,
                endDate,
            };
            const url = `${
                config[config.env].apiEndpoints.accounts
            }/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/measures`;

            const APIRes = await API.POST(url, reqBody);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const updateHospitalMeasures = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    hospitalMeasuresObj: any,
    startDate: string | null,
    endDate: string | null
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                measuresData: hospitalMeasuresObj,
                startDate,
                endDate,
                region: timeZone,
            };

            const url = `${
                config[config.env].apiEndpoints.accounts
            }/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/measures`;

            const APIRes = await API.PATCH(url, reqBody);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getProgramMeasures = async (
    programId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const url = `${
                config[config.env].apiEndpoints.catalogs
            }/programs/${programId}/categories/${categoryId}/measuretemplate`;

            const APIRes = await API.GET(url);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export async function downloadMeasureTemplate(
    programId: number,
    categoryId: number,
    measeureSelectedYear: number
) {
    if (!localStorage.getItem("userAccessToken")) {
        return false;
    }
    const myHeaders = new Headers();
    myHeaders.append(
        "authorization",
        localStorage.getItem("userAccessToken") || ""
    );
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    return new Promise((resolve, reject) => {
        const fileName =
            Number(categoryId) === CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                ? `AHA_EMS_${measeureSelectedYear}_Upload_Template.csv`
                : `AHA_QCT_Upload_Template.csv`;
        fetch(
            `${
                config[config.env].apiEndpoints.catalogs
            }/programs/${programId}/categories/${categoryId}/measure:template`,
            requestOptions
        )
            .then((response) => response.blob())
            .then((blob) => URL.createObjectURL(blob))
            .then((url) => {
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve(true);
            })
            .catch((error) => {
                const err: any = error;
                store.dispatch(showToast(err));
                reject(error);
            });
    });
}

export const getHospitalProgramMeasureMetrics = (
    hospitalId: number,
    programId: number,
    categoryId: number,
    startDate: string | null,
    endDate: string | null
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let url = `${
                config[config.env].apiEndpoints.accounts
            }/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/measureMetric?region=${encodeURIComponent(
                timeZone
            )}`;

            if (startDate && endDate) {
                url += `&startDate=${startDate}&endDate=${endDate}`;
            }

            const APIRes = await API.GET(url);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveHospitalMeasureMetrics = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    hospitalMeasureData: any,
    hospitalMetricData: any,
    startDate: string | null,
    endDate: string | null,
    patchCall: boolean | false,
    timeFrame: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                measuresData: hospitalMeasureData,
                metricsData:
                    hospitalMetricData?.length > 0 ? hospitalMetricData : null,
                startDate,
                endDate,
                timeFrame,
                region: timeZone,
            };
            const url = `${
                config[config.env].apiEndpoints.accounts
            }/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/measureMetric`;
            let APIRes: any;
            if (patchCall == true) {
                APIRes = await API.PATCH(url, reqBody);
            } else {
                APIRes = await API.POST(url, reqBody);
            }

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveEmsHospitalMeasure = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    hospitalMeasureData: any,
    year: string | null,
    patchCall: boolean | false,
    validationType?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                measuresData: hospitalMeasureData,
                metricsData: [],
                year,
                region: timeZone,
                validationType,
            };
            const url = `${
                config[config.env].apiEndpoints.accounts
            }/ems/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/measures`;
            let APIRes: any;
            if (patchCall == true) {
                APIRes = await API.PATCH(url, reqBody);
            } else {
                APIRes = await API.POST(url, reqBody);
            }

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveEmsHospitalMeasureDraft = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    hospitalMeasureData: any,
    year: string | null
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                measuresData: hospitalMeasureData,
                metricsData: [],
                year,
                region: timeZone,
            };
            const url = `${
                config[config.env].apiEndpoints.accounts
            }/ems/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/saveMeasuresDraft`;
            let APIRes: any;
            APIRes = await API.POST(url, reqBody);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
