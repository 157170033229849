import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import CONSTANTS from "common/constants";
import axios, { AxiosProgressEvent } from "axios";
import { logger } from "../utils/logger.utils";
import config from "../config";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const API = new APIUtils();

export const getPreSignedURL = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    startDate: string,
    endDate: string,
    fileName: string,
    timeFrame: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId,
                programId,
                categoryId,
                documentType: "MEASURE_UPLOAD",
                startDate,
                endDate,
                fileName,
                timeFrame,
                region: timeZone,
            };

            const APIRes = await API.POST(
                `${config[config.env].apiEndpoints.documents}/uploads`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const submitCSV = async (
    uploadId: number,
    hospitalId: number,
    programId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId,
                programId,
                categoryId,
                documentType: "MEASURE_UPLOAD",
            };

            const raw = JSON.stringify(reqBody);

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.documents
                }/uploads/${uploadId}`,
                raw
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const emsMeasureUploads = async (
    year: string,
    region: any,
    filePath: any,
    hospitalId: any,
    programId: any,
    categoryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                year,
                region,
                filePath,
                hospitalId,
                programId,
                categoryId,
                validationType: CONSTANTS.VALIDATION_TYPE,
            };

            const raw = JSON.stringify(reqBody);

            const APIRes = await API.POST(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/measureUploads`,
                raw
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const hospitalProgramUploadsPost = async (
    uploadId: any,
    programId: any,
    categoryId: any,
    fileName: any,
    startDate: any,
    endDate: any,
    hospitalId: any,
    status: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId: hospitalId || undefined,
                programId,
                categoryId,
                documentId: uploadId,
                fileName,
                fileType: "csv",
                startDate,
                endDate,
                documentType: "MEASURE_UPLOAD",
                status,
            };

            const raw = JSON.stringify(reqBody);

            const APIRes = await API.POST(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalProgramUploads`,
                raw
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const hospitalProgramUploadsPatch = async (
    uploadId: number,
    status: any,
    error?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody: any = {
                status,
            };

            if (error !== undefined) {
                reqBody.errorDetails = error;
            }

            const raw = JSON.stringify(reqBody);

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalProgramUploads/${uploadId}`,
                raw
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getUploadedCSVFileDetails = async (
    hospitalId: number,
    startDate: string,
    endDate: string,
    pageNumber: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const url = `${
                config[config.env].apiEndpoints.accounts
            }/hospitalProgramUploads/hospitals/${hospitalId}?documentType=MEASURE_UPLOAD&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${10}&hospitalId=${hospitalId}&orderBy=createdAt,DESC&datestamp=${Date.now()}`;

            const APIRes = await API.GET(url);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const downloadFileFromS3 = async (
    documentId: number | string,
    hospitalId: number
) => {
    const presignedResponse: any = await getDownloadPresignedURL(
        documentId,
        hospitalId
    );
    if (presignedResponse.success === true) {
        window.location.assign(presignedResponse.data.uploads.fileUrl);
    }
};

export const getDownloadPresignedURL = async (
    documentId: number | string,
    hospitalId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.documents
                }/uploads/hospitals/${hospitalId}/files/${documentId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const uploadfileToS3 = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    startDate: string,
    endDate: string,
    file: any,
    setUploadId: any,
    updateUploadProgress: any,
    timeFrame: number
) => {
    const presignedResponse: any = await getPreSignedURL(
        hospitalId,
        programId,
        categoryId,
        startDate,
        endDate,
        file.name,
        timeFrame
    );
    if (presignedResponse.success === true) {
        setUploadId(presignedResponse.data.uploadId);
        const axiosResponse = await axios({
            method: "put",
            url: presignedResponse.data.presignedUrl,
            data: file,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {
                "Content-Type": "text/csv",
            },
            onUploadProgress: (ev: AxiosProgressEvent) => {
                const progress = (ev.loaded / (ev?.total || Infinity)) * 100;
                updateUploadProgress(
                    Math.round(progress) === 100 ? 99 : Math.round(progress)
                );
            },
        })
            .then((response: any) => {
                if (response.status == 200) {
                    setUploadId(presignedResponse.data.uploadId);
                    updateUploadProgress(100);
                }
                return response;
            })
            .catch((error: any) => {
                logger("File upload-Error:", error);
                return error;
            });
        return axiosResponse;
    }
    return null;
};
