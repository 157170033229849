import CONSTANTS from "common/constants";
import { wordSplit } from "common/utils";
import TableRowCount from "components/TableRowCount";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
    facilities: any;
    actionURL: string;
    isTableLoading: boolean;
    pagination: any;
    roleValue: string;
}

export const RegistryFacilitiesTable = ({
    facilities,
    actionURL,
    isTableLoading,
    pagination,
    roleValue,
}: Props) => {
    return (
        <div className="mt-3 w-100">
            <TableRowCount
                isFirst={pagination.isFirst}
                pageNumber={pagination.pageNumber}
                pageSize={pagination.pageSize}
                isLast={pagination.isLast}
                totalCount={pagination.totalCount}
                roleValue={roleValue}
            />
            {isTableLoading ? (
                <div
                    role="alert"
                    aria-label="organizations registered table is loading"
                    className="sr-only"
                />
            ) : (
                ""
            )}
            <div className="registry-facilities-table mb-5 mt-3">
                <table
                    className={`aui-responsive-status-table ${
                        isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                    }`}
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th">Organization Name</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Organization Code</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th justify-content-center">
                                    Action
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {facilities &&
                            facilities.length > 0 &&
                            facilities.map((facility: any) => {
                                return (
                                    <tr
                                        key={facility.hospitalId}
                                        className="aui-table-status-success"
                                    >
                                        <td data-title="Organization Name">
                                            <div
                                                className="aui-td"
                                                id={`fac-${facility.hospitalId}`}
                                            >
                                                {facility.hospitalName}
                                            </div>
                                        </td>
                                        <td data-title="Organization Code">
                                            <div className="aui-td">
                                                <span className="abbr-code">
                                                    {wordSplit(
                                                        facility.facilityCode ||
                                                            "N/A"
                                                    )}
                                                </span>
                                            </div>
                                        </td>
                                        <td data-title="Action">
                                            <div
                                                className="aui-td justify-content-lg-center"
                                                id={`view-${facility.hospitalId}`}
                                            >
                                                <Link
                                                    to={{
                                                        pathname: `${actionURL}/${facility.hospitalId}`,
                                                        state: {
                                                            bP: pagination.pageNumber,
                                                        },
                                                    }}
                                                    aria-describedby={`fac-${facility.hospitalId}`}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-container={`#view-${facility.hospitalId}`}
                                                    title="View"
                                                >
                                                    <i
                                                        className="aha-icon-view-details"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="sr-only">
                                                        {facility.hospitalName}
                                                    </span>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                <div
                    role="alert"
                    aria-atomic="true"
                    className="table-emptystate-outer"
                >
                    {facilities && facilities.length < 1 && (
                        <div>No data to display</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RegistryFacilitiesTable;
